@mixin input-float-on-set {
  label {
    display: none;
    position: absolute;
    top: 0;
    animation: moveUp 0.5s normal;
  }
  input::selection {
    background-color: transparent;
  }

  input:focus + label,
  input:not(:placeholder-shown) + label {
    display: block !important;
    transform: translateY(0) scale(1);
    cursor: default;
    font-weight: 300;
    font-size: 14px;
    color: #a5a098;
    text-transform: capitalize;
  }
  input:focus::placeholder {
    color: transparent;
  }
}

@mixin btn($backgroundColor, $fontColor) {
  outline: none;
  background: $backgroundColor;
  color: $fontColor;
  border: 1px solid #c2c2c2;
  padding: 16px 24px;
  border-radius: 8px;
  text-transform: uppercase;
  &:hover {
    cursor: pointer;
  }
}

@keyframes moveUp {
  0% {
    top: 0px;
  }
  100% {
    top: -20px;
  }
}

@keyframes moveDown {
  0% {
    top: -20px;
  }
  100% {
    top: 0px;
  }
}
