.StoreDetails {
  .store-details-wrapper {
    .store-logo {
      margin-bottom: 14px;

      img {
        max-width: 250px;
      }
    }
    .store-name {
      font-size: 24px;
      margin-bottom: 8px;
    }
    .address {
      font-size: 12px;
      margin-bottom: 16px;
    }
    .bill {
      font-size: 18px;
      display: flex;
      align-items: center;
      .bill-amount {
        font-weight: bold;
        font-size: 20px;
        padding-left: 4px;
        padding-bottom: 1px;
        padding-right: 4px;
      }
      .svg {
        padding-top: 2px;
      }
    }
  }
}

@media (max-width: 768px) {
  .StoreDetails {
    .store-details-wrapper {
      .store-name,
      .address,
      .bill {
        text-align: center;
        justify-content: center;
      }

      .store-logo {
        text-align: center;
        img {
          max-width: 250px;
        }
      }
    }
  }
}
