.PreviousPayment {
  text-align: center;

  .payment-success {
    background: #5bae75c7 !important;
  }
  .payment-failure {
    background: #ea322fb9 !important;
  }
  .payment-details {
    color: white;
    max-width: 420px;
    min-width: 100px;
    min-height: 100px;
    max-height: 420px;
    padding: 16px;
    font-size: 20px;
    border-radius: 8px;
    .payment-content {
      display: inline-flex;
      text-align: left;
      font-size: 14px;
      .payment-content-header,
      .payment-content-value {
        ul {
          list-style: none;
          padding-left: 0;
          li {
            padding-top: 8px;
          }
        }
      }
      .payment-content-value {
        ul {
          padding-left: 16px;
        }
      }
    }
    .disclaimer {
      font-size: 10px;
      margin-top: 16px;
    }
    .try-again {
      font-size: 12px;
    }
  }
}

@media (max-width: 768px) {
  .PreviousPayment {
    .payment-details {
      margin: 8px;
    }
  }
}

@media (max-width: 448px) {
  .PreviousPayment {
    .payment-details {
      font-size: 16px;
      .payment-content {
        font-size: 10px;
      }
    }
  }
}
