.error-msg {
  text-align: left;
  font-size: 10px;
  top: 24px;
  background: #dc4e41 !important;
  line-height: 12px;
  position: absolute;
  padding: 4px 5px;
  box-shadow: 0 0 10px #fff;
  background-position: 6px;
  border-radius: 6px;
  color: #fff;
  z-index: 999;
  margin-top: 8px;
  .arrow-up {
    width: 1px;
    height: 10px;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-bottom: 6px solid #dc4e41;
    margin-left: 16px;
    margin-top: -16px;
    position: absolute;
    top: 0;
    z-index: 99;
    left: 0;
  }
}
