@import "../../mixins/formMixins.scss";
*, *::before, *::after {
  box-sizing: border-box;
}



.footer-logo {
  border-top: 1px solid #ddd;
    padding: 20px 15px;
    display: flex;
    align-items: center;
    justify-content: flex-end;

}
.footer-logo .fh-logo {
  max-height: 50px;
}
.Payment {
  display: flex;
  align-items: center;
  justify-content: space-around;
 min-height: calc(100vh - 95px);
  width: 100%;
  .payment-details {
  }
  .payment-card {
    max-width: 426px;
    min-width: 100px;
    width: 100%;
    text-align: center;
    .btn-retry {
      @include btn(#14a2b8, #fff);
      margin-top: 16px;
    }
    .payment-loader {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .card-details {
      background-color: #fff;
      padding: 48px 32px;
      padding-top: 80px;
      border-radius: 12px;
      box-shadow: 0 1px 2px rgba(0, 0, 0, 0.12), 0 1px 1px rgba(0, 0, 0, 0.24);
      .card-form {
        .card-name,
        .card-number {
          input {
            width: 100%;
          }
          margin-bottom: 64px;
          height: 16px;
        }
        .card-number-wrapper {
          display: flex;
          justify-content: space-between;
          align-items: center;
          .card-type {
            padding-bottom: 8px;
          }
        }
        .card-info-more {
          display: flex;
          margin-bottom: 64px;
          input {
            max-width: 160px;
            min-width: 60px !important;
            display: inline-block;
          }
          .cvv-wrapper {
            display: flex;
          }
        }
        .btn-group {
          .form-field {
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }

        .pointer {
          cursor: pointer;
        }

        .form-field {
          margin: 0 8px;
          position: relative;
          input {
            border: none;
            outline: none;
            border-bottom: 1px solid #eee;
            padding: 0;
            min-width: 196px;
            padding: 4px 0;
          }
          label {
            display: none;
            position: absolute;
            top: -20px;
          }
          input:not(:placeholder-shown) + label {
            display: block;
          }
          .btn-reset {
            @include btn(#fff, #333);
            margin-right: 16px;
          }
          .btn-pay {
            @include btn(#5bae75, #fff);
          }
          img {
            color: #232322;
          }
        }
      }
    }
  }
  .loader-wrapper {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;

    .loader,
    .loader:after {
      border-radius: 50%;
      width: 10em;
      height: 10em;
    }
    .loader {
      font-size: 10px;
      position: relative;
      text-indent: -9999em;
      // border-top: 1.1em solid rgba(255, 255, 255, 0.2);
      // border-right: 1.1em solid rgba(255, 255, 255, 0.2);
      // border-bottom: 1.1em solid rgba(255, 255, 255, 0.2);
      border-left: 1.1em solid #ea322fb9;
      -webkit-transform: translateZ(0);
      -ms-transform: translateZ(0);
      transform: translateZ(0);
      -webkit-animation: load8 1.1s infinite linear;
      animation: load8 1.1s infinite linear;
    }
    @-webkit-keyframes load8 {
      0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
      }
      100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
      }
    }
    @keyframes load8 {
      0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
      }
      100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
      }
    }
  }
}

@media (max-width: 768px) {
  .Payment {
    overflow: auto;
    display: block;
    width: 100%;
    .payment-card {
      margin: auto;
      .payment-loader {
        margin-top: 24px;
      }
    }
    .payment-details {
      margin: auto;
      display: flex;
      justify-content: center;
      margin-top: 16px;
    }
    .card-details {
      margin: 32px auto;
      padding: 64px 16px;
      box-sizing: border-box;
      max-width: 426px;
      .card-form {
        .card-info-more {
          justify-content: space-between;
          input {
            width: 80px;
          }
        }
      }
    }
  }
}

@media (max-width: 448px) {
  .Payment {
    .card-details {
      margin: 32px 16px;
      .card-form {
        .form-field {
          input {
            min-width: 136px !important;
          }
        }
        .card-info-more {
          .cvv-wrapper {
            .form-field {
              input {
                max-width: 10px !important;
              }
            }
          }
        }
      }
    }
  }
}

@include input-float-on-set;
